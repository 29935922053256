<template>
  <div class='py-8'>
    <welcome-title title='Floor Plan' />
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/apsr_floor_plan_1st_floor.png' class='block my-4 max-w-5xl w-full' />
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/apsr_floor_plan_2nd_floor.png' class='block my-4 max-w-5xl w-full' />
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/apsr_floor_plan_3rd_floor.png' class='block my-4 max-w-5xl w-full' />
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/apsr_floor_plan_4th_floor.png' class='block my-4 max-w-2xl w-full' />
    <welcome-title title='Exhibition' class='mt-16' />
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/apsr2022-ondemand/apsr_floor_plan_exhibition.png' class='block my-4 w-full' />
  </div>
</template>

<script>
import WelcomeTitle from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'FloorPlan',
  components: {
    WelcomeTitle,
  },
}
</script>
